import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Permission } from 'types-unicorn';
import { AuthFacade } from '../services';
import { ReplaySubject, Subject, switchMap, takeUntil, tap } from 'rxjs';

@Directive({
  selector: '[requirePermission]'
})
export class RequirePermissionDirective implements OnInit, OnDestroy {

  @Input() set requirePermission(permission: Permission) {
    this.requiredPermission$.next(permission);
  }

  private requiredPermission$ = new ReplaySubject<Permission>(1);
  private destroy$ = new Subject<boolean>();

  constructor(private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef,
              private auth: AuthFacade) {}

  ngOnInit(): void {
    this.requiredPermission$.pipe(
      takeUntil(this.destroy$),
      switchMap(permission => this.auth.userHasPermission(permission)),
      tap(userHasPermission => {
        this.viewContainer.clear();
        if (userHasPermission) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        }
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }
}
