import { createAction, props } from '@ngrx/store';
import { BnaError, List } from 'types-unicorn';

export const GetLists = createAction(
  '[ListsMainComponent] Get Lists'
);

export const GetListsSuccess = createAction(
  '[@Effect getLists] Get Lists Success',
  props<{ lists: List[] }>()
);

export const GetListsFailure = createAction(
  '[@Effect getLists] Get Lists Failure',
  props<{ error: BnaError }>()
);
