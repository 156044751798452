import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { AuthService, UserService } from '../../services';
import * as AuthActions from './auth.actions';
import * as AuthSelectors from './auth.selectors';
import { selectCurrentUsername } from './auth.selectors';
import { catchError, delay, filter, map, of, switchMap, take, tap, withLatestFrom } from 'rxjs';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectRefreshToken } from './auth.reducer';
import { DialogUnicornService } from 'dialog-unicorn';
import { BnaError } from 'types-unicorn';

@Injectable()
export class AuthEffects {

  triggerLoginProcess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.TriggerLoginProcess),
      tap(({ desiredRoute }) => this.auth.showLoginDialog(desiredRoute))
    );
  }, { dispatch: false });

  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.Login),
      switchMap(({ user, password, desiredRoute }) => this.auth.login(user, password).pipe(
        map(response => AuthActions.LoginSuccess({
          ...response,
          desiredRoute
        })),
        catchError(error => of(AuthActions.LoginFailure({ error })).pipe(
          delay(2000)
        ))
      ))
    );
  });

  loginSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.LoginSuccess),
      tap(() => this.auth.closeChangePasswordDialog()),
      map(({ desiredRoute }) => AuthActions.GetUserData({ desiredRoute }))
    );
  });

  getUserData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.GetUserData),
      switchMap(({ desiredRoute }) => this.user.getUser().pipe(
        map(user => AuthActions.GetUserDataSuccess({ user, desiredRoute })),
        catchError(error => of(AuthActions.GetUserDataFailure({ error })))
      ))
    );
  });

  getUserDataSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.GetUserDataSuccess),
      tap(({ user }) => {
        this.auth.closeDialog();
        if (user.passwordExpired) {
          this.auth.showChangePasswordDialog({ passwordExpired: true });
        }
      }),
      filter(({ user }) => !user.passwordExpired),
      tap(({ desiredRoute }) => this.router.navigate(desiredRoute.split('/')))
    );
  }, { dispatch: false });

  refreshAccessToken$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        AuthActions.RefreshAccessToken,
        AuthActions.RefreshAccessTokenAfterDeniedRequest,
      ),
      switchMap(() => this.store.select(selectRefreshToken).pipe(take(1))),
      switchMap(refreshToken => this.auth.refreshAccessToken(refreshToken).pipe(
        map(response => AuthActions.RefreshAccessTokenSuccess(response)),
        catchError(error => of(AuthActions.RefreshAccessTokenFailure({ error })))
      ))
    );
  });

  refreshAccessTokenFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.RefreshAccessTokenFailure),
      withLatestFrom(this.store.select(selectCurrentUsername)),
      tap(([_, currentUsername]) => this.auth.showRefreshLoginDialog(currentUsername))
    );
  }, { dispatch: false });

  triggerRenewLoginProcess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.TriggerRenewLoginProcess),
      withLatestFrom(this.store.select(selectCurrentUsername)),
      tap(([_, currentUsername]) => this.auth.showRefreshLoginDialog(currentUsername))
    );
  }, { dispatch: false });

  renewLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.RenewLogin),
      withLatestFrom(this.store.select(selectCurrentUsername)),
      switchMap(([action, username]) => this.auth.login(username, action.password).pipe(
        map(response => AuthActions.RenewLoginSuccess(response)),
        catchError(error => of(AuthActions.RenewLoginFailure({ error })).pipe(
          delay(2000)
        ))
      ))
    );
  });

  renewLoginSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.RenewLoginSuccess),
      tap(() => this.auth.closeDialog())
    );
  }, { dispatch: false });

  logout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        AuthActions.CancelRenewLogin,
        AuthActions.LogoutFromRenewDialog,
        AuthActions.Logout,
      ),
      tap(() => {
        this.auth.closeDialog();
        this.router.navigate(['/']);
        window.location.reload();
      }),
    );
  }, { dispatch: false });

  forgotPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        AuthActions.ForgotPasswordFromLogin,
        AuthActions.ForgotPasswordFromRefresh
      ),
      tap(({ username }) => this.auth.showForgotPasswordDialog(username))
    );
  }, { dispatch: false });

  cancelNewPasswordRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.CancelNewPasswordRequest),
      tap(() => this.auth.closeForgotPasswordDialog())
    );
  }, { dispatch: false });

  requestNewPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.RequestNewPassword),
      switchMap(({ username }) => this.auth.forgotPassword(username).pipe(
        map(() => AuthActions.RequestNewPasswordSuccess()),
        catchError(() => of(AuthActions.RequestNewPasswordFailure()))
      ))
    );
  });

  requestNewPasswordSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.RequestNewPasswordSuccess),
      tap(() => this.dialogUnicorn.showSuccessDialog({
        title: 'Demande envoyée',
        message: `Votre demande de réinitialisation de mot de passe a bien été envoyée. Vous recevrez bientôt un email avec un mot de passe temporaire.`
      })),
      delay(2000),
      tap(() => this.auth.closeForgotPasswordDialog())
    );
  }, { dispatch: false });

  showChangePasswordDialog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.ShowChangePasswordDialog),
      tap(({ passwordExpired }) => this.auth.showChangePasswordDialog({ passwordExpired }))
    );
  }, { dispatch: false });

  changePassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.ChangePassword),
      concatLatestFrom(() => this.store.select(AuthSelectors.selectCurrentUsername)),
      switchMap(([{ oldPassword, newPassword }, username]) => {
        return this.auth.changePassword(username, oldPassword, newPassword).pipe(
          map(() => AuthActions.ChangePasswordSuccess({ username, newPassword })),
          catchError((error: BnaError) => of(AuthActions.ChangePasswordFailure({ error })))
        );
      })
    );
  });

  changePasswordSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.ChangePasswordSuccess),
      map(({ username, newPassword }) => AuthActions.Login({
        user: username,
        password: newPassword,
        desiredRoute: ''
      }))
    );
  });

  constructor(private actions$: Actions,
              private auth: AuthService,
              private router: Router,
              private user: UserService,
              private dialogUnicorn: DialogUnicornService,
              private store: Store) {
  }
}
