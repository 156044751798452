import { createAction, props } from '@ngrx/store';
import { BnaError, MainNavMenuItem } from 'types-unicorn';

export const ToggleSideMenu = createAction(
  '[LayoutComponent] Toggle Side Menu'
);

export const GetMainNavMenuItems = createAction(
  '[@Effect @initializeApp] Get Main Nav Menu Items'
);

export const GetMainNavMenuItemsSuccess = createAction(
  '[@Effect @getMainNavMenuItems] Get Main Nav Menu Items Success',
  props<{ menuItems: MainNavMenuItem[] }>()
);

export const GetMainNavMenuItemsFailure = createAction(
  '[@Effect @getMainNavMenuItems] Get Main Nav Menu Items Failure',
  props<{ error: BnaError }>()
);

export const RefuseAccess = createAction(
  '[@Effect @getMainNavMenuItems] Refuse Access',
);
