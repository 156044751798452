<mat-card class="container-card">
  <mat-card-header>
    <mat-card-title>
      Mes informations
    </mat-card-title>
  </mat-card-header>
  <mat-card-content *ngIf="myProfile$ | async as myProfile">
    <p>Login : {{ myProfile.login }}</p>
    <p>Nom : {{ myProfile.nom }}</p>
    <p>Prénom : {{ myProfile.prenom }}</p>
    <p>Adresse mail : {{ myProfile.mail }}</p>
    <p>Tél fixe : {{ myProfile.telFixe }}</p>
    <p>Tél portable : {{ myProfile.telMobile }}</p>
  </mat-card-content>
  <mat-card-actions class="profile-actions">
    <button mat-stroked-button
            (click)="onUpdateProfileInfo()">MODIFIER INFORMATIONS
    </button>
    <button mat-flat-button
            color="primary"
            (click)="onChangePassword()">MODIFIER MOT DE PASSE
    </button>
  </mat-card-actions>
</mat-card>
