import { createReducer, on } from '@ngrx/store';
import * as UiActions from './ui.actions';
import { MainNavMenuItem } from 'types-unicorn';

export const featureKey = 'ui';

export interface UiState {
  menuOpen: boolean;
  menuItems: MainNavMenuItem[];
}

export const initialState: UiState = {
  menuOpen: true,
  menuItems: []
};

export const toggleSideMenu = (state: UiState): UiState => ({ ...state, menuOpen: !state.menuOpen });

export const reducer = createReducer<UiState>(
  initialState,
  on(UiActions.ToggleSideMenu, toggleSideMenu),
  on(UiActions.GetMainNavMenuItemsSuccess, (state, action): UiState => ({
    ...state,
    menuItems: action.menuItems
  }))
);

