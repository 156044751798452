import { Inject, Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { catchError, filter, map, Observable, switchMap, take, takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectAccessToken, selectRefreshingToken } from '../state/auth/auth.reducer';
import { RefreshAccessTokenAfterDeniedRequest } from '../state/auth/auth.actions';
import { AUTH_REQUEST_ENDPOINTS } from './AUTH_REQUEST_ENDPOINTS';
import * as AuthSelectors from '../state/auth/auth.selectors';
import { AUTH_CONFIG } from '../tokens/AUTH_CONFIG.token';
import { AuthUnicornConfig } from '../models';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private store: Store,
              @Inject(AUTH_CONFIG) private config: AuthUnicornConfig) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.isUnsecuredEndpointRequest(req) ?
      next.handle(req) :
      this.buildAuthorizedRequestWithTokenRefresh(next, req);
  }

  private buildAuthorizedRequestWithTokenRefresh(next: HttpHandler, req: HttpRequest<any>) {
    const authorizedRequest$ = this.buildAuthorizedRequest(next, req);

    return authorizedRequest$.pipe(
      catchError((error: HttpErrorResponse) => {
        this.attemptTokenRefreshIfErrorIs401(error);
        return authorizedRequest$;
      })
    );
  }

  private attemptTokenRefreshIfErrorIs401(error: HttpErrorResponse) {
    if (error.status === 401) {
      this.store.dispatch(RefreshAccessTokenAfterDeniedRequest());
    }
  }

  private isUnsecuredEndpointRequest(req: HttpRequest<any>): boolean {
    const requestEndpoint = this.getLastSegmentOfUrl(req.url);
    return this.isAuthRequest(requestEndpoint) || this.isPublicEndpointRequest(req);
  }

  private isPublicEndpointRequest(req: HttpRequest<any>) {
    return req.url.includes(this.config.publicBaseUrl);
  }

  private isAuthRequest(requestEndpoint: string) {
    return AUTH_REQUEST_ENDPOINTS.includes(requestEndpoint);
  }

  private getLastSegmentOfUrl(url: string): string {
    const urlParts = url.split('/');
    return urlParts[urlParts.length - 1];
  }

  private buildAuthorizedRequest(next: HttpHandler, req: HttpRequest<any>) {
    const userLoggedOutAndNotRenewing$ = this.store.select(AuthSelectors.selectUserLoggedOutAndNotRenewing).pipe(
      filter(loggedOutNotRenewing => loggedOutNotRenewing),
    );
    const finishedRefreshingToken$ = this.store.select(selectRefreshingToken).pipe(
      filter(refreshing => refreshing === false),
      take(1),
    );
    return finishedRefreshingToken$.pipe(
      switchMap(() => this.store.select(selectAccessToken)),
      take(1),
      map(token => req.clone({
        headers: new HttpHeaders().append('Authorization', `Bearer ${ token }`)
      })),
      switchMap(authenticatedRequest => next.handle(authenticatedRequest)),
      takeUntil(userLoggedOutAndNotRenewing$)
    );
  }
}
