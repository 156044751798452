import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const passwordConfirmationValidator: ValidatorFn = (changePasswordForm: AbstractControl): ValidationErrors | null => {
  const oldPasswordCtrl = changePasswordForm.get('oldPassword')! as FormControl;
  const oldPassword = oldPasswordCtrl.value;
  const newPasswordCtrl = changePasswordForm.get('newPassword')! as FormControl;
  const newPassword = newPasswordCtrl.value;
  const confirmNewPasswordCtrl = changePasswordForm.get('confirmNewPassword')! as FormControl;
  const confirmNewPassword = confirmNewPasswordCtrl.value;
  if (oldPassword === newPassword && newPasswordCtrl.dirty && !!newPassword) {
    return { passwordConfirmation: 'Le nouveau mot de passe doit être différent de l\'actuel' };
  }
  if (newPassword !== confirmNewPassword && newPasswordCtrl.dirty && confirmNewPasswordCtrl.dirty && !!newPassword && !!confirmNewPassword) {
    return { passwordConfirmation: 'Le nouveau mot de passe et sa confirmation n\'ont pas la même valeur' };
  }
  return null;
}
