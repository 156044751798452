<div *ngIf="loading$ | async" class="overlay blur-backdrop">
	<mat-spinner color="primary"/>
</div>
<h1 matDialogTitle>Demande de réinitialisation du mot de passe</h1>
<mat-dialog-content>
	<p>Nous vous renverrons un mot de passe à usage unique à l'adresse mail reliée à votre compte.</p>
	<mat-form-field>
		<mat-label>Nom d'utilisateur</mat-label>
		<input type="text"
					 matInput
					 [formControl]="usernameCtrl">
	</mat-form-field>
	<p *ngIf="errorMsg$ | async as errorMsg" class="red-icon">{{ errorMsg }}</p>
</mat-dialog-content>
<mat-dialog-actions class="flex-end">
	<button mat-stroked-button
					type="button"
					(click)="onCancel()">
		ANNULER
	</button>
	<button mat-flat-button
					type="submit"
					color="primary"
					(click)="onSubmit()">
		ENVOYER MA DEMANDE
	</button>
</mat-dialog-actions>
