import { createAction, props } from '@ngrx/store';
import { BnaError, CreateListDto, List } from 'types-unicorn';

export const CreateList = createAction(
  '[ListEditorComponent] Create List',
  props<{ list: CreateListDto }>()
);
export const CreateListSuccess = createAction(
  '[@Effect createList$] Create List Success',
  props<{ list: List, message: string }>()
);
export const CreateListFailure = createAction(
  '[@Effect createList$] Create List Failure',
  props<{ error: BnaError }>()
);
