import { createSelector } from '@ngrx/store';
import { Permission } from 'types-unicorn';
import * as fromAuth from './auth.reducer';

export const selectUserAuthenticated = createSelector(
  fromAuth.selectRefreshToken,
  refreshToken => !!refreshToken
);

export const selectCurrentUsername = createSelector(
  fromAuth.selectUser,
  user => user.login
);

export const selectCurrentUserFullName = createSelector(
  fromAuth.selectUser,
  user => user.fullName
);

export const selectPasswordExpired = createSelector(
  fromAuth.selectUser,
  user => user.passwordExpired
);

export const selectUserStructure = createSelector(
  fromAuth.selectUser,
  user => user.structure
);

export const selectUserStructureIsLiberal = createSelector(
  selectUserStructure,
  structure => structure.centreMetadata?.isLiberal ?? false
);

export const selectUserPermissions = createSelector(
  fromAuth.selectUser,
  user => user.permissions
);

export const selectUserHasPermission = (permission: Permission) => createSelector(
  selectUserPermissions,
  permissions => permissions.includes(permission)
);

export const selectUserHasAtLeastOnePermission = (requestedPermissions: Permission[]) => createSelector(
  selectUserPermissions,
  userPermissions => userHasAtLeastOneRequestedPermission(requestedPermissions, userPermissions)
);

export const selectLoadingOrLoaded = createSelector(
  fromAuth.selectLoading,
  fromAuth.selectAccessToken,
  (loading, token) => loading || !!token
);

export const selectUserProfiles = createSelector(
  fromAuth.selectUser,
  user => user.profiles
);

export const selectUserLoggedOutAndNotRenewing = createSelector(
  selectUserAuthenticated,
  fromAuth.selectRenewingLogin,
  (isAuthenticated, isRenewing) => !isAuthenticated && !isRenewing
);

const userHasAtLeastOneRequestedPermission = (requestedPermissions: Permission[], userPermissions: Permission[]) => {
  return requestedPermissions.some(permission => userPermissions.includes(permission));
};
