import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from '@admin-unicorn/app-routing.module';
import { AppComponent } from '@admin-unicorn/app.component';
import { CoreModule } from '@admin-unicorn/core/core.module';
import { AuthUnicornModule } from 'auth-unicorn';
import { environment } from '@admin-unicorn/env/environment.prod';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AuthUnicornModule.forRoot({
      baseUrl: environment.apiBaseUrl,
      refreshTokenLifespan: 60,
      publicBaseUrl: environment.publicBaseUrl,
    }),
    CoreModule,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
