import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as fromAuth from '../../state/auth/auth.reducer';
import { ForgotPasswordFromRefresh, LogoutFromRenewDialog, RenewLogin } from '../../state/auth/auth.actions';

@Component({
  selector: 'auth-refresh-login-dialog',
  templateUrl: './renew-login-dialog.component.html',
  styleUrls: ['./renew-login-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RenewLoginDialogComponent implements OnInit {
  refreshLoginForm!: FormGroup;
  errorMsg$!: Observable<string>;
  loading$!: Observable<boolean>;

  constructor(private formBuilder: FormBuilder,
              @Inject(MAT_DIALOG_DATA) private data: { currentUsername: string },
              private store: Store) {
  }

  ngOnInit(): void {
    this.buildForm();
    this.connectObservables();
  }

  private buildForm() {
    this.refreshLoginForm = this.formBuilder.group({
      user: [this.data.currentUsername, Validators.required],
      password: ['', Validators.required]
    });
    this.disableUsernameControl();
  }

  private disableUsernameControl() {
    this.refreshLoginForm.get('user')!.disable();
  }

  private connectObservables() {
    this.errorMsg$ = this.store.select(fromAuth.selectErrorMessage);
    this.loading$ = this.store.select(fromAuth.selectLoading);
  }

  onRefreshLogin() {
    this.store.dispatch(RenewLogin({ password: this.refreshLoginForm.get('password')!.value }))
  }

  onLogout() {
    this.store.dispatch(LogoutFromRenewDialog());
  }

  onForgotPassword() {
    this.store.dispatch(ForgotPasswordFromRefresh({ username: this.data.currentUsername }));
  }
}
