import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@admin-unicorn/shared/modules/material.module';
import { LoaderComponent } from '@admin-unicorn/shared/components';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
  ],
  exports: [
    MaterialModule,
    LoaderComponent,
  ],
  declarations: [
    LoaderComponent,
  ]
})
export class SharedModule { }
