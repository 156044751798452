<div *ngIf="loading$ | async" class="overlay blur-backdrop">
	<mat-spinner color="primary"/>
</div>
<form [formGroup]="changePasswordForm">
	<h1 matDialogTitle>Changer de mot de passe</h1>
	<mat-dialog-content>
		<p *ngIf="data.passwordExpired">Votre mot de passe a expiré. Veuillez créer un nouveau mot de passe.</p>
		<mat-form-field>
			<mat-label>Mot de passe actuel</mat-label>
			<input type="password"
						 matInput
						 formControlName="oldPassword">
		</mat-form-field>
		<mat-form-field>
			<mat-label>Nouveau mot de passe</mat-label>
			<input type="password"
						 matInput
						 formControlName="newPassword">
		</mat-form-field>
		<p *ngIf="validPasswordErrorMsg$ | async as errorMsg" class="red-icon">{{ errorMsg }}</p>
		<mat-form-field>
			<mat-label>Confirmer le nouveau mot de passe</mat-label>
			<input type="password"
						 matInput
						 formControlName="confirmNewPassword">
		</mat-form-field>
		<p *ngIf="passwordsEqualErrorMsg$ | async as errorMsg" class="red-icon">{{ errorMsg }}</p>
		<div *ngIf="serverError$ | async as error" class="red-icon">
      <p>{{ error.message }}</p>
      <ul *ngIf="error.subErrors as subErrors">
        <li *ngFor="let subError of subErrors">{{ subError }}</li>
      </ul>
    </div>
	</mat-dialog-content>
	<mat-dialog-actions class="flex-end">
		<button mat-stroked-button
						*ngIf="!data.passwordExpired"
						mat-dialog-close
						type="button">
			ANNULER
		</button>
		<button mat-flat-button
						type="submit"
						color="primary"
						(click)="onChangePassword()">
			SOUMETTRE
		</button>
	</mat-dialog-actions>
</form>

