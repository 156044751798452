import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'auth-unicorn';

const routes: Routes = [
  { path: 'dashboard', canActivate: [AuthGuard], loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: 'lists', canActivate: [AuthGuard], loadChildren: () => import('./lists/lists.module').then(m => m.ListsModule) },
  { path: 'trace', canActivate: [AuthGuard], loadChildren: () => import('./trace/trace.module').then(m => m.TraceModule) },
  { path: 'my-profile', canActivate: [AuthGuard], loadChildren: () => import('auth-unicorn').then(m => m.MyProfileModule) },
  { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
  { path: '**', redirectTo: 'dashboard' }
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    {
      scrollPositionRestoration: 'enabled',
      scrollOffset: [0, 0]
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
