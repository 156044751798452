import { BnaError, List } from 'types-unicorn';
import { createFeature, createReducer, on } from '@ngrx/store';
import * as TraceActions from './trace.actions';
import { TraceSummary } from '@admin-unicorn/trace/models/trace-summary.model';
import { Dictionary } from '@ngrx/entity';
import { Trace } from '@admin-unicorn/trace/models/trace.model';
import { TraceSearchParams } from '@admin-unicorn/trace/models/trace-search-params.model';

const featureKey = 'trace';

export interface TraceState {
  loading: boolean;
  loaded: boolean;
  traceSummaries: TraceSummary[];
  traceDetails: Dictionary<Trace>
  error: BnaError;
  detailsLoading: boolean;
  activeFilters: TraceSearchParams,
  actionsList: List,
}

const initialState: TraceState = {
  loading: false,
  loaded: false,
  traceSummaries: [],
  traceDetails: {},
  error: BnaError.nullError(),
  detailsLoading: false,
  activeFilters: {
    top: 50,
  },
  actionsList: List.empty(),
};

export const traceFeature = createFeature({
  name: featureKey,
  reducer: createReducer(
    initialState,
    on(
      TraceActions.GetLatestTrace,
      (state): TraceState => ({
        ...state,
        loading: true,
        loaded: false,
        error: BnaError.nullError(),
      })
    ),
    on(
      TraceActions.SetActiveFilters,
      (state, action): TraceState => ({
        ...state,
        activeFilters: action.params
      })
    ),
    on(
      TraceActions.GetTraceWithParams,
      (state): TraceState => ({
        ...state,
        loading: true,
        loaded: false,
        error: BnaError.nullError(),
      })
    ),
    on(
      TraceActions.GetTraceSuccess,
      (state, action): TraceState => ({
        ...state,
        loading: false,
        loaded: true,
        traceSummaries: action.traceSummaries
      })
    ),
    on(
      TraceActions.GetTraceFailure,
      (state, action): TraceState => ({
        ...state,
        loading: false,
        loaded: true,
        traceSummaries: [],
        error: action.error
      })
    ),
    on(
      TraceActions.GetTraceById,
      (state): TraceState => ({
        ...state,
        detailsLoading: true
      })
    ),
    on(
      TraceActions.GetTraceByIdSuccess,
      (state, action): TraceState => {
        const newTraceDetails = { ...state.traceDetails };
        newTraceDetails[action.trace.id] = action.trace;
        return {
          ...state,
          detailsLoading: false,
          traceDetails: newTraceDetails
        };
      }
    ),
    on(
      TraceActions.GetTraceByIdFailure,
      (state, action): TraceState => ({
        ...state,
        detailsLoading: false,
        error: action.error
      })
    ),
    on(
      TraceActions.GetActionTypesListSuccess,
      (state, action): TraceState => ({
        ...state,
        actionsList: action.list
      })
    ),
    on(
      TraceActions.GetActionTypesListFailure,
      (state, action): TraceState => ({
        ...state,
        error: action.error
      })
    ),
    on(
      TraceActions.RefreshTraceData,
      (state): TraceState => ({
        ...state,
        loading: true,
        loaded: false,
        error: BnaError.nullError(),
      })
    )
  )
});

export const {
  name,
  reducer,
  selectTraceSummaries,
  selectTraceDetails,
  selectLoading,
  selectDetailsLoading,
  selectActiveFilters,
  selectActionsList,
  selectError,
  selectLoaded
} = traceFeature;
