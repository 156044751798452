import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyProfileRoutingModule } from './my-profile-routing.module';
import { MatCardModule } from '@angular/material/card';
import { StoreModule } from '@ngrx/store';
import * as fromMyProfile from './state/my-profile/my-profile.reducer';
import { EffectsModule } from '@ngrx/effects';
import { MyProfileEffects } from './state/my-profile/my-profile.effects';
import { MatButtonModule } from '@angular/material/button';
import { ProfilePageComponent } from './components/profile-page/profile-page.component';
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [
    ProfilePageComponent,
    EditProfileComponent
  ],
  imports: [
    CommonModule,
    MyProfileRoutingModule,
    MatCardModule,
    StoreModule.forFeature(fromMyProfile.myProfileFeature),
    EffectsModule.forFeature([
      MyProfileEffects
    ]),
    MatButtonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule
  ]
})
export class MyProfileModule {}
