import { createAction, props } from '@ngrx/store';
import { LoginEndpointResponse, User } from '../../models';
import { BnaError } from 'types-unicorn';

export const TriggerLoginProcess = createAction(
  '[AuthGuard] Trigger Login Process',
  props<{ desiredRoute: string }>()
);

export const Login = createAction(
  '[Auth Unicorn] Login',
  props<{ user: string, password: string, desiredRoute: string }>()
);

export const LoginSuccess = createAction(
  '[Auth API] Login Success',
  props<LoginEndpointResponse & { desiredRoute: string }>()
);

export const LoginFailure = createAction(
  '[Auth API] Login Failure',
  props<{ error: string }>()
);

export const GetUserData = createAction(
  '[@Effect loginSuccess$] Get User Data',
  props<{ desiredRoute: string }>()
);

export const GetUserDataSuccess = createAction(
  '[@Effect getUserData$] Get User Data Success',
  props<{ user: User, desiredRoute: string }>()
);

export const GetUserDataFailure = createAction(
  '[@Effect getUserData$] Get User Data Failure',
  props<{ error: string }>()
);

export const Logout = createAction(
  '[Auth Unicorn] Logout'
);

export const LogoutFromRenewDialog = createAction(
  '[RenewLoginDialogComponent] Logout From Renew Dialog'
);

export const CancelRenewLogin = createAction(
  '[RefreshLoginDialogComponent] Cancel Renew Login'
);

export const RefreshAccessToken = createAction(
  '[Auth Unicorn] Refresh Access Token'
);

export const RefreshAccessTokenAfterDeniedRequest = createAction(
  '[AuthInterceptor] Refresh Access Token After Denied Request'
);

export const RefreshAccessTokenSuccess = createAction(
  '[Auth API] Refresh Access Token Success',
  props<LoginEndpointResponse>()
);

export const RefreshAccessTokenFailure = createAction(
  '[Auth API] Refresh Access Token Failure',
  props<{ error: string }>()
);

export const TriggerRenewLoginProcess = createAction(
  '[@Effect refreshAccessTokenFailure$] Trigger Renew Login Process'
);

export const RenewLogin = createAction(
  '[RenewLoginDialogComponent] Renew Login',
  props<{ password: string }>()
);

export const RenewLoginSuccess = createAction(
  '[@Effect renewLogin$] Renew Login Success',
  props<LoginEndpointResponse>()
);

export const RenewLoginFailure = createAction(
  '[@Effect renewLogin$] Renew Login Failure',
  props<{ error: string}>()
);

export const ForgotPasswordFromLogin = createAction(
  '[LoginDialogComponent] Forgot Password',
  props<{ username: string }>()
);

export const ForgotPasswordFromRefresh = createAction(
  '[RenewLoginDialogComponent] Forgot Password',
  props<{ username: string }>()
);

export const RequestNewPassword = createAction(
  '[RequestNewPasswordComponent] Request New Password',
  props<{ username: string }>()
);

export const RequestNewPasswordSuccess = createAction(
  '[@Effect requestNewPassword$] Request New Password Success',
);

export const RequestNewPasswordFailure = createAction(
  '[@Effect requestNewPassword$] Request New Password Failure',
);

export const CancelNewPasswordRequest = createAction(
  '[RequestNewPasswordComponent] Cancel New Password Request'
);

export const ShowChangePasswordDialog = createAction(
  '[AuthFacade] Show Change Password Dialog',
  props<{ passwordExpired: boolean }>()
);

export const ChangePassword = createAction(
  '[ChangePasswordComponent] Change Password',
  props<{ oldPassword: string, newPassword: string }>()
);

export const ChangePasswordSuccess = createAction(
  '[@Effect changePassword$] Change Password Success',
  props<{ username: string, newPassword: string }>()
);

export const ChangePasswordFailure = createAction(
  '[@Effect changePassword$] Change Password Failure',
  props<{ error: BnaError }>()
);
