import { createAction, props } from '@ngrx/store';
import { TraceSearchParams } from '@admin-unicorn/trace/models/trace-search-params.model';
import { BnaError, List } from 'types-unicorn';
import { TraceSummary } from '@admin-unicorn/trace/models/trace-summary.model';
import { Trace } from '@admin-unicorn/trace/models/trace.model';

export const GetLatestTrace = createAction(
  '[TraceMainComponent] Get Latest Trace'
);

export const SetActiveFilters = createAction(
  '[TraceMainComponent] Set Active Filters',
  props<{ params: TraceSearchParams }>()
);

export const GetTraceWithParams = createAction(
  '[TraceMainComponent] Get Trace With Params',
);

export const GetTraceSuccess = createAction(
  '[@Effect getTraces$] Get Trace Success',
  props<{ traceSummaries: TraceSummary[] }>()
);

export const GetTraceFailure = createAction(
  '[@Effect getTraces$] Get Trace Failure',
  props<{ error: BnaError }>()
);

export const ViewTraceDetails = createAction(
  '[TraceMainComponent] View Trace Details',
  props<{ traceId: string }>()
);

export const GetTraceById = createAction(
  '[@Effect viewTraceDetails$] Get Trace By Id',
  props<{ traceId: string }>()
);

export const GetTraceByIdSuccess = createAction(
  '[@Effect getTraceById$] Get Trace By Id Success',
  props<{ trace: Trace }>()
);

export const GetTraceByIdFailure = createAction(
  '[Effect getTraceById$] Get Trace By Id Failure',
  props<{ error: BnaError }>()
);

export const GetActionTypesListSuccess = createAction(
  '[@Effect getTrace$] Get Action Types List Success',
  props<{ list: List }>()
);

export const GetActionTypesListFailure = createAction(
  '[@Effect getTrace$] Get Action Types List Failure',
  props<{ error: BnaError }>()
);

export const RefreshTraceData = createAction(
  '[TraceMainComponent] Refresh Trace Data'
);
