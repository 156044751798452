<div *ngIf="loading$ | async" class="overlay blur-backdrop">
  <mat-spinner color="primary"/>
</div>
<form [formGroup]="loginForm">
  <h1 matDialogTitle>Connexion</h1>
  <mat-dialog-content>
    <p>Merci de vous authentifier.</p>
    <mat-form-field>
      <mat-label>Nom d'utilisateur</mat-label>
      <input type="text"
             matInput
             formControlName="user">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Mot de passe</mat-label>
      <input type="password"
             matInput
             formControlName="password">
    </mat-form-field>
    <p *ngIf="errorMsg$ | async as errorMsg" class="red-icon">{{ errorMsg }}</p>
  </mat-dialog-content>
  <mat-dialog-actions class="left-right-split">
    <button mat-stroked-button
            (click)="onForgotPassword()"
            type="button">
      MOT DE PASSE OUBLIÉ
    </button>
    <button mat-flat-button
            type="submit"
            color="primary"
            (click)="onLogin()">
      CONNEXION
    </button>
  </mat-dialog-actions>
</form>

