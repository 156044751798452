import { Component, OnInit } from '@angular/core';
import { filter, Observable } from 'rxjs';
import { UserProfile } from '../../models/user-profile.model';
import { Store } from '@ngrx/store';
import * as MyProfileActions from '../../state/my-profile/my-profile.actions';
import * as fromMyProfile from '../../state/my-profile/my-profile.reducer';
import { AuthFacade } from '../../services';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'bna-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss']
})
export class ProfilePageComponent implements OnInit {
  myProfile$!: Observable<UserProfile>;

  constructor(private store: Store,
              private authFacade: AuthFacade,
              private router: Router,
              private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.store.dispatch(MyProfileActions.GetMyProfile());
    this.myProfile$ = this.store.select(fromMyProfile.selectProfile).pipe(
      filter(profile => !UserProfile.isNullProfile(profile))
    );
  }

  onChangePassword(): void {
    this.authFacade.changePassword();
  }

  onUpdateProfileInfo() {
    this.router.navigate(['./edit'], { relativeTo: this.route });
  }
}
