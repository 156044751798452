<mat-list-item [routerLink]="menuItem.route" routerLinkActive="active">
	<div matListItemAvatar class="menu-item-icon">
		<ellipse-icon [iconName]="menuItem.iconName" iconSize="xl" class="greyed-icon"/>
	</div>
  <span matListItemTitle>
    {{ menuItem.displayLabel }}
  </span>
  <div matListItemMeta class="badge" [ngStyle]="{ opacity: menuItem.badge ? 1 : 0 }">
    <p>{{ menuItem.badge }}</p>
  </div>
</mat-list-item>
