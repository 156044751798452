import { NgModule } from '@angular/core';
import { RequirePermissionDirective } from './directives';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [RequirePermissionDirective],
  imports: [CommonModule],
  exports: [RequirePermissionDirective]
})
export class PermissionsUnicornModule {}
