export class UserProfile {
  login!: string;
  prenom!: string;
  nom!: string;
  mail!: string;
  telFixe!: string;
  telMobile!: string;

  static nullProfile(): UserProfile {
    return {
      login: '',
      prenom: '',
      nom: '',
      mail: '',
      telFixe: '',
      telMobile: ''
    }
  }

  static isNullProfile(profile: UserProfile): boolean {
    const nullProfile = UserProfile.nullProfile();
    return Object.keys(profile)
      .every((key) => nullProfile[key as keyof UserProfile] === profile[key as keyof UserProfile]) &&
      Object.entries(profile).length === Object.entries(nullProfile).length;
  }
}
