import { CanActivateFn, Router } from '@angular/router';
import { AuthFacade } from '../services';
import { Observable, tap } from 'rxjs';
import { inject } from '@angular/core';
import { Permission } from 'types-unicorn';

export const PermissionsGuard = (permissions: Permission[]): CanActivateFn => (): Observable<boolean> => {
  const auth: AuthFacade = inject(AuthFacade);
  const router: Router = inject(Router);
  return auth.userHasAtLeastOnePermission(permissions).pipe(
    tap(hasPermission => {
      if (!hasPermission) {
        router.navigate([]);
      }
    })
  );
}
