export class GeneralUtils {

  static moveItemInArray<T>(array: T[], previousIndex: number, newIndex: number): T[] {
    const newArray = [...array];
    newArray.splice(previousIndex, 1);
    newArray.splice(newIndex, 0, array[previousIndex]);
    return newArray;
  }

  static removeItemInArray<T>(array: T[], index: number): T[] {
    const newArray = [...array];
    newArray.splice(index, 1);
    return newArray;
  }

  static replaceItemInArray<T>(array: T[], index: number, newItem: T): T[] {
    return array.map((item, i): T => i === index ? newItem : item);
  }

}
