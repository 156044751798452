import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthUnicornConfig, LoginEndpointResponse } from '../models';
import { AUTH_CONFIG } from '../tokens/AUTH_CONFIG.token';
import { HttpClient } from '@angular/common/http';
import {
  ChangePasswordComponent,
  LoginDialogComponent,
  RenewLoginDialogComponent,
  RequestNewPasswordComponent
} from '../components';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Injectable()
export class AuthService {

  private dialogRef?: MatDialogRef<LoginDialogComponent | RenewLoginDialogComponent>;
  private forgotPasswordDialogRef?: MatDialogRef<RequestNewPasswordComponent>;
  private changePasswordDialogRef?: MatDialogRef<ChangePasswordComponent>;

  constructor(private http: HttpClient,
              private dialog: MatDialog,
              @Inject(AUTH_CONFIG) private authConfig: AuthUnicornConfig) {}

  login(user: string, password: string): Observable<LoginEndpointResponse> {
    return this.http.post<LoginEndpointResponse>(`${this.authConfig.baseUrl}/login`, { user, password });
  }

  logout(): Observable<void> {
    return this.http.post<void>(`${this.authConfig.baseUrl}/logout`, {});
  }

  refreshAccessToken(refreshToken: string): Observable<LoginEndpointResponse> {
    return this.http.post<LoginEndpointResponse>(`${this.authConfig.baseUrl}/refresh`, {
      refresh_token: refreshToken,
      grant_type: 'refresh_token'
    });
  }

  showLoginDialog(desiredRoute: string = '/'): void {
    this.dialogRef = this.dialog.open(LoginDialogComponent, {
      width: '70%',
      disableClose: true,
      data: { desiredRoute },
      backdropClass: 'blur-backdrop'
    });
  }

  showRefreshLoginDialog(currentUsername: string): void {
    this.dialogRef = this.dialog.open(RenewLoginDialogComponent, {
      width: '70%',
      disableClose: true,
      data: { currentUsername },
      backdropClass: 'blur-backdrop'
    });
  }

  showForgotPasswordDialog(username: string): void {
    this.forgotPasswordDialogRef = this.dialog.open(RequestNewPasswordComponent, {
      width: '70%',
      disableClose: true,
      data: { username },
      backdropClass: 'blur-backdrop'
    });
  }

  showChangePasswordDialog(data: { passwordExpired: boolean } = { passwordExpired: false }): void {
    this.changePasswordDialogRef = this.dialog.open(ChangePasswordComponent, {
      width: '70%',
      disableClose: true,
      backdropClass: 'blur-backdrop',
      data
    });
  }

  closeDialog() {
    this.dialogRef?.close();
  }

  closeForgotPasswordDialog(): void {
    this.forgotPasswordDialogRef?.close();
  }

  closeChangePasswordDialog(): void {
    this.changePasswordDialogRef?.close();
  }

  forgotPassword(username: string): Observable<{ message: string }> {
    return this.http.post<{ message: string }>(`${this.authConfig.publicBaseUrl}/users/reset-password`, { login: username });
  }

  changePassword(username: string, oldPassword: string, newPassword: string): Observable<{ message: string }> {
    return this.http.put<{ message: string }>(`${this.authConfig.baseUrl}/users/change-password/${username}`, { oldPassword, newPassword });
  }

  checkPassword(password: string): Observable<{ isValidPassword: boolean, message?: string }> {
    return this.http.post<{ isValidPassword: boolean, message?: string }>(`${this.authConfig.baseUrl}/users/check-password`, { password });
  }
}
