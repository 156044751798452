import { createAction, props } from '@ngrx/store';
import { BnaError, List } from 'types-unicorn';

export const ToggleList = createAction(
  '[ListsMainComponent] Toggle List',
  props<{ list: List }>()
);
export const ConfirmToggleList = createAction(
  '[@Effect toggleList$] Confirm Toggle List',
  props<{ listId: number, newState: boolean }>()
);
export const CancelToggleList = createAction(
  '[@Effect toggleList$] Cancel Toggle List'
);
export const ToggleListSuccess = createAction(
  '[@Effect confirmToggleList$] Toggle List Success',
  props<{ listId: number, message: string, newState: boolean }>()
);
export const ToggleListFailure = createAction(
  '[@Effect confirmToggleList$] Toggle List Failure',
  props<{ error: BnaError }>()
);
