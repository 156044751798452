import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MyProfileService } from '../../services/my-profile.service';
import * as MyProfileActions from './my-profile.actions';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class MyProfileEffects {

  getMyProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MyProfileActions.GetMyProfile),
      switchMap(() => this.myProfile.getMyProfile().pipe(
        map(({ message, userProfile }) => MyProfileActions.GetMyProfileSuccess({ message, userProfile })),
        catchError(error => of(MyProfileActions.GetMyProfileFailure({ error })))
      ))
    );
  });

  setMyProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MyProfileActions.UpdateMyProfile),
      switchMap(({ userProfile }) => this.myProfile.setMyProfile(userProfile).pipe(
        map(({ message }) => MyProfileActions.UpdateMyProfileSuccess({ message, userProfile })),
        catchError(error => of(MyProfileActions.UpdateMyProfileFailure({ error })))
      ))
    );
  });

  setMyProfileSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MyProfileActions.UpdateMyProfileSuccess),
      tap(() => this.router.navigate(['my-profile'],))
    )
  }, { dispatch: false });

  constructor(private actions$: Actions,
              private myProfile: MyProfileService,
              private router: Router) {}
}
