import { isEqual } from 'lodash';
import { Permission } from 'types-unicorn';

export class User {
  id!: string;
  login!: string;
  permissions: Permission[] = [];
  passwordExpired: boolean = false;
  fullName!: string;
  profiles!: string[];
  structure!: {
    centreMetadata?: {
      isCentre?: boolean,
      isLiberal?: boolean,
    }
  };
  nom!: string;
  prenom!: string;
}

export const NullUser: User = {
  id: '',
  login: '',
  permissions: [],
  passwordExpired: false,
  fullName: '',
  profiles: [],
  structure: {},
  nom: '',
  prenom: ''
};

export const isNullUser = (user: User) => {
  return isEqual(user, NullUser);
}
