<ellipse-toolbar titleText="BNA Admin"
                 (menuButtonClick)="onOpenMenu()"
                 [currentUserFullName]="currentUserFullName$ | async"
                 (logout)="onLogout()"
                 (changePassword)="onChangePassword()"
                 (showProfile)="onShowProfile()"
/>
<mat-drawer-container>
  <mat-drawer mode="side" *ngIf="menuItems$ | async as menuItems" [opened]="(menuOpen$ | async)!">
    <ellipse-main-nav-menu [menuItems]="menuItems"/>
  </mat-drawer>
  <mat-drawer-content>
    <div class="content">
      <router-outlet/>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
<admin-loader *ngIf="globalLoading$ | async"/>
