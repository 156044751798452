import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, Observable } from 'rxjs';
import * as AuthActions from '../state/auth/auth.actions';
import { Router } from '@angular/router';
import * as AuthSelectors from '../state/auth/auth.selectors';
import { Permission } from 'types-unicorn';
import { User } from '../models';
import { authFeature } from '../state/auth/auth.reducer';

@Injectable()
export class AuthFacade {
  constructor(private store: Store,
              private router: Router) {}

  currentUserFullName(): Observable<string> {
    return this.store.select(AuthSelectors.selectCurrentUserFullName).pipe(
      filter(name => !!name)
    );
  }

  currentUser(): Observable<User> {
    return this.store.select(authFeature.selectUser);
  }

  userIsAuthenticated(): Observable<boolean> {
    return this.store.select(AuthSelectors.selectUserAuthenticated);
  }

  userHasAtLeastOnePermission(permissions: Permission[]): Observable<boolean> {
    return this.store.select(AuthSelectors.selectUserHasAtLeastOnePermission(permissions));
  }

  userHasPermission(permission: Permission): Observable<boolean> {
    return this.store.select(AuthSelectors.selectUserHasPermission(permission))
  }

  userPermissions(): Observable<Permission[]> {
    return this.store.select(AuthSelectors.selectUserPermissions).pipe(
      filter(permissions => permissions.length > 0)
    );
  }

  userStructureIsLiberal(): Observable<any> {
    return this.store.select(AuthSelectors.selectUserStructureIsLiberal);
  }

  changePassword(): void {
    this.store.dispatch(AuthActions.ShowChangePasswordDialog({ passwordExpired: false }));
  }

  logout() {
    this.router.navigate(['/']).then(() => this.store.dispatch(AuthActions.Logout()));
  }
}
