import { createFeature, createReducer, on } from '@ngrx/store';
import * as AuthActions from './auth.actions';
import { NullUser, User } from '../../models';
import { AUTH_ERROR_MESSAGES } from '../../errors/error-messages';
import { BnaError } from 'types-unicorn';

const featureKey = 'auth';

export interface AuthState {
  loading: boolean,
  accessToken: string,
  accessTokenExpiresAt: number,
  refreshToken: string,
  errorMessage: string;
  error: BnaError;
  refreshingToken: boolean;
  renewingLogin: boolean;
  currentUsername: string;
  currentUserFullName: string;
  user: User;
  passwordResetError: string;
}

const initialState: AuthState = {
  loading: false,
  accessToken: '',
  accessTokenExpiresAt: 0,
  refreshToken: '',
  errorMessage: '',
  error: BnaError.nullError(),
  refreshingToken: false,
  renewingLogin: false,
  currentUsername: '',
  currentUserFullName: '',
  user: NullUser,
  passwordResetError: ''
};

export const authFeature = createFeature({
  name: featureKey,
  reducer: createReducer(
    initialState,
    on(
      AuthActions.Login,
      AuthActions.ChangePassword,
      (state => ({
        ...state,
        loading: true,
        errorMessage: '',
      }))),
    on(
      AuthActions.RenewLogin,
      (state): AuthState => ({
        ...state,
        loading: true,
        errorMessage: '',
        renewingLogin: true,
      })
    ),
    on(
      AuthActions.LoginSuccess,
      AuthActions.RenewLoginSuccess,
      (state, action): AuthState => ({
        ...state,
        loading: false,
        accessToken: action.access_token,
        refreshToken: action.refresh_token,
        accessTokenExpiresAt: action.exp * 1000,
        errorMessage: '',
        refreshingToken: false,
        renewingLogin: false,
      })),
    on(
      AuthActions.Logout,
      AuthActions.CancelRenewLogin,
      AuthActions.LogoutFromRenewDialog,
      (() => initialState)),
    on(AuthActions.RefreshAccessTokenSuccess, (state, action): AuthState => ({
      ...state,
      accessToken: action.access_token,
      refreshToken: action.refresh_token,
      accessTokenExpiresAt: action.exp * 1000,
      errorMessage: '',
      refreshingToken: false,
      renewingLogin: false,
    })),
    on(
      AuthActions.LoginFailure,
      state => ({
        ...state,
        loading: false,
        errorMessage: AUTH_ERROR_MESSAGES.LoginFailure,
        refreshingToken: false,
      })
    ),
    on(
      AuthActions.RenewLoginFailure,
      (state): AuthState => ({
        ...state,
        loading: false,
        errorMessage: AUTH_ERROR_MESSAGES.LoginFailure,
      })
    ),
    on(
      AuthActions.RefreshAccessToken,
      AuthActions.RefreshAccessTokenAfterDeniedRequest,
      state => ({
        ...state,
        refreshingToken: true,
      })),
    on(
      AuthActions.GetUserDataFailure,
      state => ({
        ...state,
        loading: false,
        errorMessage: AUTH_ERROR_MESSAGES.DefaultFailure,
        accessToken: '',
        refreshToken: '',
        accessTokenExpiresAt: 0,
      })),
    on(
      AuthActions.RefreshAccessTokenFailure, state => ({
        ...state,
        loading: false,
        errorMessage: AUTH_ERROR_MESSAGES.TokenExpired,
        accessToken: '',
        refreshToken: '',
        accessTokenExpiresAt: 0,
        renewingLogin: true
      })),
    on(
      AuthActions.GetUserData,
      AuthActions.RequestNewPassword,
      (state): AuthState => ({
        ...state,
        loading: true
      })),
    on(AuthActions.GetUserDataSuccess, (state, action): AuthState => ({
      ...state,
      user: action.user,
      loading: false
    })),
    on(
      AuthActions.RequestNewPasswordSuccess,
      (state): AuthState => ({
        ...state,
        loading: false,
        passwordResetError: ''
      })),
    on(AuthActions.RequestNewPasswordFailure, (state): AuthState => ({
      ...state,
      loading: false,
      passwordResetError: AUTH_ERROR_MESSAGES.DefaultFailure
    })),
    on(AuthActions.CancelNewPasswordRequest, (state): AuthState => ({
      ...state,
      passwordResetError: ''
    })),
    on(AuthActions.ChangePassword, (state): AuthState => ({
      ...state,
      loading: true,
      errorMessage: '',
      error: BnaError.nullError()
    })),
    on(AuthActions.ChangePasswordSuccess, (state): AuthState => ({
      ...state,
      loading: false
    })),
    on(AuthActions.ChangePasswordFailure, (state, action): AuthState => ({
      ...state,
      loading: false,
      errorMessage: action.error.message,
      error: action.error,
    }))
  )
});

export const {
  name,
  reducer,
  selectAccessToken,
  selectRefreshToken,
  selectErrorMessage,
  selectRefreshingToken,
  selectUser,
  selectLoading,
  selectPasswordResetError,
  selectError,
  selectRenewingLogin
} = authFeature;
