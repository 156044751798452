import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { FormBuilder, FormControl } from '@angular/forms';
import * as AuthActions from '../../state/auth/auth.actions';
import * as fromAuth from '../../state/auth/auth.reducer';
import { Observable } from 'rxjs';

@Component({
  selector: 'auth-forgot-password',
  templateUrl: './request-new-password.component.html',
  styleUrls: ['./request-new-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequestNewPasswordComponent implements OnInit {

  usernameCtrl!: FormControl;
  loading$!: Observable<boolean>;
  errorMsg$!: Observable<string>;

  constructor(@Inject(MAT_DIALOG_DATA) private data: { username: string },
              private store: Store,
              private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.connectStoreObservables();
    this.buildForm();
  }

  private connectStoreObservables() {
    this.loading$ = this.store.select(fromAuth.selectLoading);
    this.errorMsg$ = this.store.select(fromAuth.selectPasswordResetError);
  }

  onSubmit(): void {
    this.store.dispatch(AuthActions.RequestNewPassword({ username: this.usernameCtrl.value }));
  }

  private buildForm() {
    this.usernameCtrl = this.formBuilder.control(this.data.username || '');
  }

  onCancel() {
    this.store.dispatch(AuthActions.CancelNewPasswordRequest());
  }
}
