<mat-card class="container-card">
	<mat-card-header>
		<mat-card-title>
			Modifier mes informations
		</mat-card-title>
	</mat-card-header>
	<mat-card-content class="profile-form-content">
		<form *ngIf="myProfile$ | async" [formGroup]="editProfileForm">
			<mat-form-field>
				<mat-label>Login</mat-label>
				<input matInput type="text" formControlName="login">
			</mat-form-field>
			<mat-form-field>
				<mat-label>Nom</mat-label>
				<input matInput type="text" formControlName="nom">
			</mat-form-field>
			<mat-form-field>
				<mat-label>Prénom</mat-label>
				<input matInput type="text" formControlName="prenom">
			</mat-form-field>
			<mat-form-field>
				<mat-label>Mail</mat-label>
				<input matInput type="email" formControlName="mail">
			</mat-form-field>
			<mat-form-field>
				<mat-label>Téléphone fixe</mat-label>
				<input matInput type="text" formControlName="telFixe">
			</mat-form-field>
			<mat-form-field>
				<mat-label>Téléphone mobile</mat-label>
				<input matInput type="text" formControlName="telMobile">
			</mat-form-field>
		</form>
	</mat-card-content>
	<mat-card-actions>
		<button mat-stroked-button (click)="onCancel()">ANNULER</button>
		<button mat-flat-button color="primary" (click)="onSave()">ENREGISTRER</button>
	</mat-card-actions>
</mat-card>
