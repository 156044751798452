import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgrxModule } from '@admin-unicorn/core/modules/ngrx.module';
import { LayoutComponent } from './components/layout/layout.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@admin-unicorn/shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EllipseToolbarModule } from 'ui-components/toolbar';
import { EllipseMainNavMenuModule } from 'ui-components/main-nav-menu';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { fr } from 'date-fns/locale';
import { ErrorInterceptor } from '@admin-unicorn/core/interceptors/error.interceptor';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

@NgModule({
  declarations: [
    LayoutComponent
  ],
  exports: [
    LayoutComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    NgrxModule,
    EllipseToolbarModule,
    EllipseMainNavMenuModule,
    SharedModule,
    RouterModule,
    HttpClientModule,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: fr },
    { provide: MAT_DATE_FORMATS, useValue: {
        parse: {
          dateInput: 'yyyy-MM-dd',
        },
        display: {
          dateInput: 'yyyy-MM-dd',
          monthYearLabel: 'MMMM yyyy',
          dateA11yLabel: 'dd MMMM yyyy',
          monthYearA11yLabel: 'MMMM yyyy',
        },
      }
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        subscriptSizing: 'dynamic'
      }
    }
  ]
})
export class CoreModule { }
