import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { InitializeApp } from '@admin-unicorn/core/state/core/core.actions';
import { catchError, combineLatest, delay, map, of, switchMap, tap } from 'rxjs';
import {
  GetMainNavMenuItems,
  GetMainNavMenuItemsFailure,
  GetMainNavMenuItemsSuccess,
  RefuseAccess
} from '@admin-unicorn/core/state/ui/ui.actions';
import { NavMenuService } from '@admin-unicorn/core/services/nav-menu.service';
import { DialogUnicornService } from 'dialog-unicorn';
import { MainNavMenuItem, Permission } from 'types-unicorn';
import { AuthFacade } from 'auth-unicorn';

@Injectable()
export class UiEffects {

  initializeApp$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(InitializeApp),
      map(() => GetMainNavMenuItems())
    );
  });

  getMainNavMenuItems$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GetMainNavMenuItems),
      switchMap(() => combineLatest([
        this.navMenu.getNavMenuItems(),
        this.auth.userPermissions()
      ]).pipe(
        map(([menuItems, permissions]) => menuItems.length > 0 ?
          GetMainNavMenuItemsSuccess({ menuItems: this.filterMenuItemsByUserPermissions(menuItems, permissions) }) :
          RefuseAccess()
        ),
        catchError(error => of(GetMainNavMenuItemsFailure({ error })))
      ))
    );
  });

  getMainNavMenuItemsFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GetMainNavMenuItemsFailure),
      tap(error => this.dialog.showErrorDialog(error))
    );
  }, { dispatch: false });

  refuseAccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RefuseAccess),
      tap(() => this.dialog.showAccessDeniedDialog('assets/images/bob.png')),
      delay(1500),
      tap(() => this.auth.logout())
    );
  }, { dispatch: false });

  constructor(private actions$: Actions,
              private navMenu: NavMenuService,
              private dialog: DialogUnicornService,
              private auth: AuthFacade) {
  }

  private filterMenuItemsByUserPermissions(items: MainNavMenuItem[], userPermissions: Permission[]) {
    return items.filter(item => !item.permissions || item.permissions.some(permission => userPermissions.includes(permission)));
  }
}
