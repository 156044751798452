import { createAction, props } from '@ngrx/store';
import { UserProfile } from '../../models/user-profile.model';
import { BnaError } from 'types-unicorn';

export const GetMyProfile = createAction(
  '[ProfilePageComponent] Get My Profile'
);

export const GetMyProfileSuccess = createAction(
  '[@Effect getMyProfile$] Get My Profile Success',
  props<{ message: string, userProfile: UserProfile }>()
);

export const GetMyProfileFailure = createAction(
  '[@Effect getMyProfile$] Get My Profile Failure',
  props<{ error: BnaError }>()
);

export const UpdateMyProfile = createAction(
  '[ProfilePageComponent] Update My Profile',
  props<{ userProfile: UserProfile }>()
);

export const UpdateMyProfileSuccess = createAction(
  '[@Effect setMyProfile$] Update My Profile Success',
  props<{ message: string, userProfile: UserProfile }>()
);

export const UpdateMyProfileFailure = createAction(
  '[@Effect setMyProfile$] Update My Profile Failure',
  props<{ error: BnaError }>()
);
