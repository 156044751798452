import { ActivatedRouteSnapshot, CanActivateChildFn, RouterStateSnapshot } from '@angular/router';
import { Observable, take, tap } from 'rxjs';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectUserAuthenticated } from '../state/auth/auth.selectors';
import { TriggerLoginProcess } from '../state/auth/auth.actions';

export const ChildAuthGuard: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
  const store = inject(Store);

  return store.select(selectUserAuthenticated).pipe(
    take(1),
    tap((userAuthenticated) => {
      if (!userAuthenticated) {
        store.dispatch(TriggerLoginProcess({ desiredRoute: state.url }))
      }
    })
  );
}
