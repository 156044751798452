<mat-toolbar color="primary"
             class="mat-elevation-z5">
  <div class="start">
    <button mat-icon-button
            (click)="onMenuButtonClick($event)">
      <ellipse-icon iconName="fa-regular fa-bars" />
    </button>
    <div>
      {{ titleText }}
    </div>
  </div>
  <div class="end"
       *ngIf="currentUserFullName">
    <button mat-icon-button
            [matTooltip]="currentUserFullName"
            [matMenuTriggerFor]="userMenu"
            class="logout-button">
      <ellipse-icon iconName="fa-regular fa-user" />
    </button>
    <mat-menu #userMenu="matMenu">
      <button mat-menu-item (click)="onEditProfile()">
        <ellipse-icon iconName="fa-regular fa-address-card"/>
        <span class="menu-item-text">Mes informations</span>
      </button>
      <button mat-menu-item (click)="onChangePassword()">
        <ellipse-icon iconName="fa-regular fa-key"/>
        <span class="menu-item-text">Changer de mot de passe</span>
      </button>
      <button mat-menu-item (click)="onLogout()">
        <ellipse-icon iconName="fa-regular fa-person-to-door"/>
        <span class="menu-item-text">Déconnexion</span>
      </button>
    </mat-menu>
  </div>
</mat-toolbar>
