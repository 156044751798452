import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { map, Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as AuthActions from '../../state/auth/auth.actions';
import * as fromAuth from '../../state/auth/auth.reducer';
import { passwordConfirmationValidator } from '../../validators/password-confirmation.validator';
import { BnaError } from 'types-unicorn';

@Component({
  selector: 'auth-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangePasswordComponent implements OnInit {
  loading$!: Observable<boolean>;
  changePasswordForm!: FormGroup;
  validPasswordErrorMsg$!: Observable<string>;
  passwordsEqualErrorMsg$!: Observable<string>;
  serverError$!: Observable<BnaError>;

  constructor(private formBuilder: FormBuilder,
              private store: Store,
              @Inject(MAT_DIALOG_DATA) public data: { passwordExpired: boolean }) {
  }

  ngOnInit(): void {
    this.connectStoreObservables();
    this.buildForm();
    this.connectFormErrorListeners();
  }

  onChangePassword() {
    if (!this.changePasswordForm.valid) {
      return;
    }
    const passwords = {
      oldPassword: this.changePasswordForm.get('oldPassword')!.value,
      newPassword: this.changePasswordForm.get('newPassword')!.value,
    };
    this.store.dispatch(AuthActions.ChangePassword(passwords));
  }

  private connectStoreObservables(): void {
    this.loading$ = this.store.select(fromAuth.selectLoading);
    this.serverError$ = this.store.select(fromAuth.selectError);
  }

  private buildForm(): void {
    this.changePasswordForm = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmNewPassword: ['', Validators.required]
    }, {
      validators: [passwordConfirmationValidator],
    });
  }

  private connectFormErrorListeners() {
    const newPasswordCtrl = this.changePasswordForm.get('newPassword')!;
    this.validPasswordErrorMsg$ = newPasswordCtrl.statusChanges.pipe(
      map(() => newPasswordCtrl.getError('validPassword'))
    );
    this.passwordsEqualErrorMsg$ = this.changePasswordForm.statusChanges.pipe(
      map(() => this.changePasswordForm.getError('passwordConfirmation'))
    );
  }
}
