import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainNavMenuComponent } from './main-nav-menu/main-nav-menu.component';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MainNavMenuItemComponent } from './main-nav-menu/main-nav-menu-item/main-nav-menu-item.component';
import { RouterModule } from '@angular/router';
import { EllipseIconModule } from 'ui-components';
import { MatBadgeModule } from '@angular/material/badge';

@NgModule({
  declarations: [
    MainNavMenuComponent,
    MainNavMenuItemComponent
  ],
  imports: [
    CommonModule,
    MatListModule,
    MatButtonModule,
    EllipseIconModule,
    RouterModule,
    MatBadgeModule
  ],
  exports: [
    MainNavMenuComponent
  ]
})
export class EllipseMainNavMenuModule { }
