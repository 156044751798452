import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromLists from '@admin-unicorn/lists/state/reducers/lists.reducer';
import { ListsState, selectListOpenInEditor } from '@admin-unicorn/lists/state/reducers/lists.reducer';
import { List } from 'types-unicorn';

export * from '../reducers/lists.reducer';

export const selectListsFeature = createFeatureSelector<ListsState>(fromLists.featureKey);

export const selectLists = createSelector(
  selectListsFeature,
  fromLists.selectAll
);

export const selectListEntities = createSelector(
  selectListsFeature,
  fromLists.selectEntities
);

export const selectListById = (id: number) => createSelector(
  selectListEntities,
  lists => lists[id]!
);

export const selectFilteredListsAsArray = createSelector(
  selectLists,
  fromLists.selectListsFilterValue,
  (lists, filterValue) => lists.filter(list => list.label.toLocaleLowerCase().includes(filterValue))
);

export const selectListUniqueKeys = (currentListKey?: string) => createSelector(
  selectListEntities,
  lists => (Object.values(lists) as List[])
    .map(list => list.uniqueKey)
    .filter(key => key !== currentListKey)
);

export const selectItemsForOpenList = createSelector(
  fromLists.selectListOpenInEditor,
  list => list?.items ?? []
);

export const selectCurrentUniqueItemKeysForOpenList = createSelector(
  selectItemsForOpenList,
  items => items.map(item => item.uniqueKey)
);

export const selectParentListItemsForOpenList = createSelector(
  selectListEntities,
  selectListOpenInEditor,
  (listEntities, openList) => {
    const parentList = openList.parentList;
    return parentList ? listEntities[parentList]!.items : [];
  }
);

export const selectChangesMadeToOpenList = fromLists.listsFeature.selectChangesMadeToOpenList;

export const selectPossibleParentListsForOpenList = createSelector(
  selectLists,
  selectListOpenInEditor,
  (lists, openList) => lists.filter(list => list.uniqueKey !== openList.uniqueKey)
);

export const selectItemsForListByListId = (id: number) => createSelector(
  selectListById(id),
  list => list.items
);

export const selectIsListOpenInEditor = (id: number) => createSelector(
  selectListOpenInEditor,
  list => List.alreadyExists(list) ? list.id === id : false
);
