import { Component, OnInit } from '@angular/core';
import * as MyProfileActions from '../../state/my-profile/my-profile.actions';
import * as fromMyProfile from '../../state/my-profile/my-profile.reducer';
import { filter, Observable, tap } from 'rxjs';
import { UserProfile } from '../../models/user-profile.model';
import { Store } from '@ngrx/store';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'auth-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {

  myProfile$!: Observable<UserProfile>;
  editProfileForm!: FormGroup;

  constructor(private store: Store,
              private formBuilder: FormBuilder,
              private router: Router,
              private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.getProfile();
  }

  private getProfile() {
    this.store.dispatch(MyProfileActions.GetMyProfile());
    this.myProfile$ = this.store.select(fromMyProfile.selectProfile).pipe(
      filter(profile => !UserProfile.isNullProfile(profile)),
      tap(profile => this.initForm(profile))
    );
  }

  private initForm(userProfile: UserProfile) {
    this.editProfileForm = this.formBuilder.group({
      login: this.formBuilder.control({ value: userProfile.login, disabled: true }),
      nom: [userProfile.nom, [
        Validators.required,
        Validators.maxLength(100)
      ]],
      prenom: [userProfile.prenom, [
        Validators.required,
        Validators.maxLength(60)
      ]],
      mail: [userProfile.mail, [
        Validators.required,
        Validators.email,
        Validators.maxLength(80)
      ]],
      telFixe: [userProfile.telFixe, [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(20)
      ]],
      telMobile: [userProfile.telMobile, [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(20)
      ]]
    });
  }

  onSave(): void {
    this.store.dispatch(MyProfileActions.UpdateMyProfile({ userProfile: this.editProfileForm.value }));
  }

  onCancel(): void {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
