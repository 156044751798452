import { UserProfile } from '../../models/user-profile.model';
import { createFeature, createReducer, on } from '@ngrx/store';
import * as MyProfileActions from './my-profile.actions';

export const featureKey = 'my-profile';

export interface MyProfileState {
  profile: UserProfile;
}

export const initialState: MyProfileState = {
  profile: UserProfile.nullProfile()
}

export const myProfileFeature = createFeature({
  name: featureKey,
  reducer: createReducer(
    initialState,
    on(
      MyProfileActions.GetMyProfileSuccess,
      (state, { userProfile }): MyProfileState => ({
      ...state,
      profile: userProfile
    })),
    on(
      MyProfileActions.UpdateMyProfileSuccess,
      (state, { userProfile }): MyProfileState => ({
        ...state,
        profile: {
          ...userProfile,
          ...state.profile
        }
      })
    )
  )
});

export const {
  selectProfile,
} = myProfileFeature
