import { Component, Input } from '@angular/core';
import { MainNavMenuItem } from 'types-unicorn';

@Component({
  selector: 'ellipse-main-nav-menu-item',
  templateUrl: './main-nav-menu-item.component.html',
  styleUrls: ['./main-nav-menu-item.component.scss']
})
export class MainNavMenuItemComponent {

  @Input() menuItem!: MainNavMenuItem;

  constructor() { }

}
