import { ModuleWithProviders, NgModule } from '@angular/core';
import { AuthUnicornConfig } from './models';
import { AUTH_CONFIG } from './tokens/AUTH_CONFIG.token';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './interceptors';
import { StoreModule } from '@ngrx/store';
import * as fromAuth from './state/auth/auth.reducer';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './state/auth/auth.effects';
import { MatDialogModule } from '@angular/material/dialog';
import {
  ChangePasswordComponent,
  LoginDialogComponent,
  RenewLoginDialogComponent,
  RequestNewPasswordComponent
} from './components';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AuthFacade, AuthService, UserService } from './services';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DialogUnicornModule } from 'dialog-unicorn';

@NgModule({
  declarations: [
    LoginDialogComponent,
    RenewLoginDialogComponent,
    RequestNewPasswordComponent,
    ChangePasswordComponent,
  ],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromAuth.name, fromAuth.reducer),
    EffectsModule.forFeature([AuthEffects]),
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    DialogUnicornModule,
  ],
  exports: [
    LoginDialogComponent,
    ChangePasswordComponent
  ],
  providers: [
    AuthService,
    AuthFacade,
    UserService
  ]
})
export class AuthUnicornModule {
  static forRoot(config: AuthUnicornConfig): ModuleWithProviders<AuthUnicornModule> {
    return {
      ngModule: AuthUnicornModule,
      providers: [
        { provide: AUTH_CONFIG, useValue: config },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
      ]
    }
  }

  static forChild(): ModuleWithProviders<AuthUnicornModule> {
    return {
      ngModule: AuthUnicornModule,
      providers: [
        { provide: AUTH_CONFIG, useValue: {} }
      ]
    }
  }
}
