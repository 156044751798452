import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MainNavMenuItem } from 'types-unicorn';
import { map, Observable } from 'rxjs';
import { environment } from '@anxious-unicorn/env/environment';

@Injectable({
  providedIn: 'root'
})
export class NavMenuService {
  constructor(private http: HttpClient) {}

  getNavMenuItems(): Observable<MainNavMenuItem[]> {
    return this.http.get<{ menuItems: MainNavMenuItem[] }>(`${environment.baseUrl}/ui-config/admin`).pipe(
      map(({ menuItems }) => menuItems)
    );
  }
}
