import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { UserProfile } from '../models/user-profile.model';
import { AUTH_CONFIG } from '../tokens/AUTH_CONFIG.token';
import { AuthUnicornConfig } from '../models';

@Injectable({
  providedIn: 'root'
})
export class MyProfileService {
  constructor(private http: HttpClient,
              @Inject(AUTH_CONFIG) private config: AuthUnicornConfig) {}

  getMyProfile(): Observable<{ message: string, userProfile: UserProfile }> {
    return this.http.get<{ message: string, utilisateur: UserProfile }>(`${this.config.baseUrl}/users/get-my-infos`).pipe(
      map(({ message, utilisateur }) => ({ message, userProfile: utilisateur }))
    );
  }

  setMyProfile(profile: Omit<UserProfile, 'login'>): Observable<{ message: string }> {
    return this.http.put<{ message: string }>(`${this.config.baseUrl}/users/set-my-infos`, profile);
  }
}
