import { Inject, Injectable } from '@angular/core';
import { AuthUnicornConfig, User } from '../models';
import { Observable } from 'rxjs';
import { AUTH_CONFIG } from '../tokens/AUTH_CONFIG.token';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class UserService {

  constructor(@Inject(AUTH_CONFIG) private authConfig: AuthUnicornConfig,
              private http: HttpClient) {}

  getUser(): Observable<User> {
    return this.http.get<User>(`${this.authConfig.baseUrl}/users/me`);
  }
}
