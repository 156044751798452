import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '@admin-unicorn/env/environment';

import * as fromUi from '../state/ui/ui.reducer';
import { UiEffects } from '@admin-unicorn/core/state/ui/ui.effects';

@NgModule({
  imports: [
    StoreModule.forRoot({
      [fromUi.featureKey]: fromUi.reducer
    }),
    EffectsModule.forRoot([
      UiEffects
    ]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production , connectInZone: true})
  ]
})
export class NgrxModule {}
