import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ellipse-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent {

  @Input() titleText!: string;
  @Input() currentUserFullName?: string | null;
  @Output() logout = new EventEmitter();
  @Output() changePassword = new EventEmitter();
  @Output() showProfile = new EventEmitter();
  @Output() menuButtonClick = new EventEmitter<MouseEvent>();

  constructor() { }

  onMenuButtonClick(event: MouseEvent): void {
    this.menuButtonClick.emit(event);
  }

  onLogout(): void {
    this.logout.emit();
  }

  onChangePassword() {
    this.changePassword.emit();
  }

  onEditProfile() {
    this.showProfile.emit();
  }
}
