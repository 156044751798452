import { Component, Input } from '@angular/core';
import { MainNavMenuItem } from 'types-unicorn';

@Component({
  selector: 'ellipse-main-nav-menu',
  templateUrl: './main-nav-menu.component.html',
  styleUrls: ['./main-nav-menu.component.scss']
})
export class MainNavMenuComponent {

  @Input() menuItems!: MainNavMenuItem[];

  constructor() { }

}
