import { Component, OnInit } from '@angular/core';
import { filter, take, tap } from 'rxjs';
import * as CoreActions from './core/state/core/core.actions';
import { AuthFacade } from 'auth-unicorn';
import { Store } from '@ngrx/store';

@Component({
  selector: 'admin-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private auth: AuthFacade,
              private store: Store) {
  }

  ngOnInit(): void {
    this.initAppDataWhenUserAuthenticated();
  }

  private initAppDataWhenUserAuthenticated() {
    this.auth.userIsAuthenticated().pipe(
      filter(isAuth => !!isAuth),
      take(1),
      tap(() => {
        this.store.dispatch(CoreActions.InitializeApp());
      })
    ).subscribe();
  }
}
