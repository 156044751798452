import { createAction, props } from '@ngrx/store';
import { List, ListItem } from 'types-unicorn';

export const SetListsFilterValue = createAction(
  '[ListsMainComponent] Set Lists Filter Value',
  props<{ filterValue: string }>()
);

export const OpenListInEditor = createAction(
  '[ListsMainComponent] Open List In Editor',
  props<{ list: List }>()
);

export const GetListsFromResolver = createAction(
  '[ListEditorResolver] Get Lists From Resolver'
);

export const SelectListForEditorInResolver = createAction(
  '[ListEditorResolver] Select List For Editor In Resolver',
  props<{ list: List }>()
);

export const StartCreatingNewList = createAction(
  '[ListsMainComponent] Start Creating New List'
);

export const CloseListEditor = createAction(
  '[ListEditorComponent] Close List Editor'
);

export const MakeChangeToOpenList = createAction(
  '[ListEditorComponent] Make Change To Open List',
  props<{ list: Partial<List> }>()
);

export const MoveListItemInArray = createAction(
  '[ListEditorComponent] Move List Item In Array',
  props<{ previousIndex: number, currentIndex: number }>()
);

export const ToggleListItem = createAction(
  '[ListEditorComponent] Toggle List Item',
  props<{ itemId: number }>()
);

export const OpenNewListItemDialog = createAction(
  '[ListEditorComponent] Open New List Item Dialog'
);

export const SaveNewListItem = createAction(
  '[@Effect openNewListItemDialog$] Save New List Item',
  props<{ item: ListItem }>()
);

export const ModifyListItem = createAction(
  '[ListEditorComponent] Modify List Item',
  props<{ item: ListItem, index: number }>()
);

export const SaveChangesToListItem = createAction(
  '[@Effect openModifyListItemDialog$] Save Changes To List Item',
  props<{ item: ListItem, index: number }>()
);

export const SaveOpenList = createAction(
  '[ListEditorComponent] Save Open List'
);

export const CancelListEditor = createAction(
  '[ListEditorComponent] Cancel List Editor'
);

export const DiscardChangesToList = createAction(
  '[@Effect openDiscardChangesDialog$] Discard Changes To List'
);
