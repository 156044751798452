import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { BnaError, CreateListDto, List, ListItem } from 'types-unicorn';
import { createFeature, createReducer, on } from '@ngrx/store';
import * as ListsActions from '../actions/lists.actions';

import { GeneralUtils } from '@admin-unicorn/core/utils/general.utils';
import { GetLists, GetListsFailure, GetListsSuccess } from '@admin-unicorn/lists/state/actions/get-lists.actions';
import {
  CreateList,
  CreateListFailure,
  CreateListSuccess
} from '@admin-unicorn/lists/state/actions/create-list.actions';
import {
  ConfirmToggleList,
  ToggleListFailure,
  ToggleListSuccess
} from '@admin-unicorn/lists/state/actions/delete-list.actions';
import { UpdateListFailure, UpdateListSuccess } from '@admin-unicorn/lists/state/actions/update-list.actions';

export const featureKey = 'lists';

export interface ListsState extends EntityState<List> {
  listsFilterValue: string;
  loading: boolean;
  allListsLoaded: boolean;
  error: BnaError | null;
  listOpenInEditor: List | CreateListDto;
  selectedListDeletedItems: number[];
  changesMadeToOpenList: boolean;
}

export const sortByLabel = (a: List, b: List): number => a.label.localeCompare(b.label);

export const adapter: EntityAdapter<List> = createEntityAdapter<List>({
  sortComparer: sortByLabel
});

export const initialState: ListsState = adapter.getInitialState({
  listsFilterValue: '',
  loading: false,
  allListsLoaded: false,
  error: null,
  listOpenInEditor: List.newList(),
  selectedListDeletedItems: [],
  changesMadeToOpenList: false,
});

export const listsFeature = createFeature({
  name: featureKey,
  reducer: createReducer(
    initialState,
    on(GetLists, (state): ListsState => ({ ...state, loading: true })),
    on(GetListsSuccess, (state, action): ListsState => adapter.addMany(action.lists, {
      ...state,
      loading: false,
      allListsLoaded: true
    })),
    on(GetListsFailure, (state, action): ListsState => ({
      ...state,
      error: action.error,
      loading: false
    })),
    on(ListsActions.SetListsFilterValue, (state, action): ListsState => ({
      ...state,
      listsFilterValue: action.filterValue.toLocaleLowerCase()
    })),
    on(ConfirmToggleList, (state): ListsState => ({ ...state, loading: true })),
    on(ToggleListSuccess, (state, action): ListsState => adapter.updateOne({
      id: action.listId,
      changes: { active: action.newState }
    }, {
      ...state,
      loading: false
    })),
    on(ToggleListFailure, (state, action): ListsState => ({ ...state, loading: false, error: action.error })),
    on(UpdateListSuccess, (state, action): ListsState => adapter.updateOne(action.update, { ...state, selectedListDeletedItems: [] })),
    on(UpdateListFailure, (state, action): ListsState => ({ ...state, loading: false, error: action.error })),
    on(CreateList, (state): ListsState => ({ ...state, loading: true })),
    on(CreateListSuccess, (state, action): ListsState => adapter.addOne(action.list, { ...state, loading: false })),
    on(CreateListFailure, (state, action): ListsState => ({ ...state, loading: false, error: action.error })),
    on(
      ListsActions.OpenListInEditor,
      ListsActions.SelectListForEditorInResolver,
      (state, action): ListsState => ({ ...state, listOpenInEditor: action.list })),
    on(ListsActions.StartCreatingNewList, (state): ListsState => ({ ...state, listOpenInEditor: List.newList() })),
    on(ListsActions.CloseListEditor, (state): ListsState => ({ ...state, listOpenInEditor: List.newList(), changesMadeToOpenList: false })),
    on(ListsActions.MakeChangeToOpenList, (state, action): ListsState => ({
      ...state,
      changesMadeToOpenList: true,
      listOpenInEditor: {
        ...state.listOpenInEditor,
        ...action.list
      }
    })),
    on(ListsActions.MoveListItemInArray, (state, action): ListsState => ({
      ...state,
      changesMadeToOpenList: true,
      listOpenInEditor: {
        ...state.listOpenInEditor,
        items: GeneralUtils.moveItemInArray(state.listOpenInEditor.items, action.previousIndex, action.currentIndex)
      }
    })),
    on(ListsActions.ToggleListItem, (state, { itemId }): ListsState => ({
      ...state,
      changesMadeToOpenList: true,
      listOpenInEditor: {
        ...state.listOpenInEditor,
        items: state.listOpenInEditor.items.map(item => item.id === itemId ?
          { ...item, active: !item.active } :
          item
        )
      }
    })),
    on(ListsActions.SaveNewListItem, (state, action): ListsState => ({
      ...state,
      changesMadeToOpenList: true,
      listOpenInEditor: {
        ...state.listOpenInEditor,
        items: [...state.listOpenInEditor.items, action.item]
      }
    })),
    on(ListsActions.SaveChangesToListItem, (state, action): ListsState => ({
      ...state,
      changesMadeToOpenList: true,
      listOpenInEditor: {
        ...state.listOpenInEditor,
        items: GeneralUtils.replaceItemInArray<ListItem>(state.listOpenInEditor.items, action.index, action.item)
      }
    })),
    on(ListsActions.DiscardChangesToList, (state): ListsState => ({
      ...state,
      selectedListDeletedItems: []
    })),
  )
});

export const {
  name,
  reducer,
  selectLoading,
  selectListsFilterValue,
  selectListOpenInEditor,
  selectAllListsLoaded,
  selectSelectedListDeletedItems,
} = listsFeature;

export const {
  selectAll,
  selectEntities,
  selectIds,
  selectTotal
} = adapter.getSelectors();
