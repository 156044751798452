import { createAction, props } from '@ngrx/store';
import { BnaError, List } from 'types-unicorn';
import { Update } from '@ngrx/entity';

export const UpdateList = createAction(
  '[ListEditorComponent] Update List',
  props<{ update: Update<List> }>()
);
export const UpdateListSuccess = createAction(
  '[@Effect updateList$] Update List Success',
  props<{ update: Update<List>, message: string }>()
);
export const UpdateListFailure = createAction(
  '[@Effect updateList$] Update List Failure',
  props<{ error: BnaError }>()
);
