import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as AuthActions from '../../state/auth/auth.actions';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import * as fromAuth from '../../state/auth/auth.reducer';
import * as AuthSelectors from '../../state/auth/auth.selectors';

@Component({
  selector: 'auth-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginDialogComponent implements OnInit {

  loginForm!: FormGroup;
  errorMsg$!: Observable<string>;
  loading$!: Observable<boolean>;

  constructor(private formBuilder: FormBuilder,
              @Inject(MAT_DIALOG_DATA) private data: { desiredRoute: string },
              private store: Store) {
  }

  ngOnInit(): void {
    this.buildForm();
    this.connectObservables();
  }

  private buildForm() {
    this.loginForm = this.formBuilder.group({
      user: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  private connectObservables() {
    this.errorMsg$ = this.store.select(fromAuth.selectErrorMessage);
    this.loading$ = this.store.select(AuthSelectors.selectLoadingOrLoaded);
  }

  onLogin() {
    this.store.dispatch(AuthActions.Login({
      ...this.loginForm.value,
      desiredRoute: this.data.desiredRoute
    }));
  }

  onForgotPassword() {
    const username = this.loginForm.get('user')!.value || '';
    this.store.dispatch(AuthActions.ForgotPasswordFromLogin({ username }));
  }
}
