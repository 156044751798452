import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromUi from './ui.reducer';

export const selectUiState = createFeatureSelector<fromUi.UiState>(fromUi.featureKey);

export const selectMenuOpen = createSelector(
  selectUiState,
  state => state.menuOpen
);

export const selectMainNavMenuItems = createSelector(
  selectUiState,
  state => state.menuItems
);
